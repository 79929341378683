import { Component } from '@angular/core';

@Component({
  selector: 'app-sanson-toques',
  templateUrl: './sanson-toques.component.html',
  styleUrls: ['./sanson-toques.component.scss']
})
export class SanSonToquesComponent {
  title = 'toques';
}
