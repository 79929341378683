import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    loadChildren: './sanson-toques/sanson-toques.module#SansonToquesModule',
  },
  {
    path: 'centros-comerciales',
    loadChildren: './centros-comerciales/centros-comerciales.module#CentrosComercialesModule',
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
