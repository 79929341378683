import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CcComponent } from './cc.component';


const routes: Routes = [
  {
    path: '',
    component: CcComponent
  },
  { path: '**', redirectTo: '' },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CcRoutingModule {
  static routes: Routes=routes;
}
