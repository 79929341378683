<nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#">
    <img
      src="assets/img/sansonlogo.png"
      height="60"
      class="d-inline-block"
      alt=""
    />
  </a>

  <p class="title-logo">SanSon<span> Cubano</span> <span class="last-word ">Bogotá</span></p>

  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse nav-move" id="navbarSupportedContent">
    <ul class="navbar-nav mx-auto">
      <li class="nav-item">
        <a
          class="nav-link"
          href="#"
          (click)="goToS1()"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          >INICIO <span class="sr-only">(current)</span></a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="#"
          (click)="goToS5()"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          >SERVICIOS</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="#"
          (click)="goToNuestrosArtistas()"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          >NUESTROS ARTISTAS</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="#"
          (click)="goToS2()"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          >NOSOTROS</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="#"
          (click)="goToS4()"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          >REPERTORIO</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="#"
          (click)="goToS3()"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          >GALERÍA</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="#"
          (click)="goToS7()"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          >CONTÁCTENOS</a
        >
      </li>
    </ul>
  </div>
</nav>
