<div class="con5">
  <div class="container">
    <h3 class="card-title">BIENVENIDOS</h3>
    <p class="card-text mt-3" style="padding-bottom: 0 !important;">
      Gracias por visitarnos, somos la mejor alternativa para sus eventos por nuestro variado repertorio, 
      experiencia y compromiso. Contamos con:
    </p>
    <ul>
      <li>Solista</li>
      <li>Duos</li>
      <li>Trios</li>
    </ul>

    <!-- <p>
      Entre sus principales servicios se encuentran:
    </p> -->
    <!-- <div class="row justify-content-md-center" style="margin-bottom: 10px;">
      <div class="col-6 col-sm-6 col-md-3 align-self-center">
        <div class="card mb-3 border-0">
          <div class="card-body sec-info">
            <img src="../../assets/flat/sing.svg" />
            <h5 class="card-info">Cantante</h5>
          </div>
        </div>
      </div>
      <div class="col-6 col-sm-6 col-md-3 align-self-center">
        <div class="card mb-3 border-0">
          <div class="card-body sec-info">
            <img src="../../assets/flat/guitar2.svg" />
            <h5 class="card-info">Guitarra</h5>
          </div>
        </div>
      </div>
      <div class="col-6 col-sm-6 col-md-3 align-self-center">
        <div class="card mb-3 border-0">
          <div class="card-body sec-info">
            <img class="img-big" src="../../assets/flat/arp.svg" />
            <h5 class="card-info">Arpa</h5>
          </div>
        </div>
      </div> -->
    <!-- <div class="col-sm-12 col-md-2 align-self-center">
        <div class="card mb-3 border-0">
          <div class="card-body sec-info">
            <img src="../../assets/flat/saxo.svg" />
            <h5 class="card-info">Saxofón</h5>
          </div>
        </div>
      </div> -->
    <!-- <div class="col-6 col-sm-6 col-md-3 align-self-center">
        <div class="card mb-3 border-0">
          <div class="card-body sec-info">
            <img src="../../assets/flat/violin2.svg" />
            <h5 class="card-info">Violín</h5>
          </div>
        </div>
      </div> -->
    <!-- <div class="soundcloud ">
        <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/66503379&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=true"></iframe>
      </div>
  </div>-->

    <div class="row justify-content-md-center">
      <div class="col-sm-12 col-md-6">
        <div class="videoWrapper">
          <iframe allowFullScreen="allowFullScreen"
            src="https://www.youtube.com/embed/mc0HILpzHAc?si=Pzja-FyFMbDgMP-l" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            width="560" height="315" allowtransparency="true" frameborder="0">
          </iframe>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 align-self-center">

        <div class="card-body sec-info">
          <p class="card-text">
            El grupo SanSon Cubano esta integrado por músicos de trayectoria que han trabajo con artistas como Gilberto Santa Rosa, Adriana Lucia, Ricarena entre otros. 
            <br><br>Contamos con sonido propio incluido en el precio por hora, hasta para <span>60</span> personas.
          </p>
        </div>
        <button (click)="goToS7()" type="button" class="btn btn-warning btn-lg btn-block mt-3">
          ¡Reserva ahora!
        </button>
      </div>
    </div>

  </div>


  <div class="row justify-content-md-center no-horizontal-margin">
    <div class="align-self-center">
      <div class="card mb-3 border-0">
        <div class="card-body text-wrap">
          <h5 class="card-title iserra-text">12 años trabajando con el centro comercial Iserra 100</h5>
          <div class="img_isierra">
            <img src="../../assets/images/listonLogo.jpg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>