import { Component, OnInit } from '@angular/core';
import { NgscrollService } from '../../services/ngscroll.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  constructor(public ngScrollService: NgscrollService) { }

  ngOnInit() {
  }


  public goToS1() {
    this.ngScrollService.goTo('s1');
  }

  public goToS2() {
    this.ngScrollService.goTo('s2');
  }

  public goToS3() {
    this.ngScrollService.goTo('s3');
  }

  public goToS4() {
    this.ngScrollService.goTo('s4');
  }

  public goToS5() {
    this.ngScrollService.goTo('s5');
  }

  public goToS6() {
    this.ngScrollService.goTo('s6');
  }

  public goToS7() {
    this.ngScrollService.goTo('s7');
  }

  public goToNuestrosArtistas() {
    this.ngScrollService.goTo('nuestros-artistas');
  }
}
