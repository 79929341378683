import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {

  @Input()
  mostrarMensaje: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  openWhastApp() {
    window.location.href = 'https://api.whatsapp.com/send?phone=573103171380';
  }

  openMailTo() {
    window.location.href = 'mailto:gruposansoncubano@gmail.com';
  }

  callNow() {
    window.location.href = 'tel:3103171380';
  }
}
