import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SansonToquesRoutingModule } from './sanson-toques-routing.module';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { S1Component } from './components/s1/s1.component';
import { S2Component } from './components/s2/s2.component';
import { S5Component } from './components/s5/s5.component';
import { S4Component } from './components/s4/s4.component';
import { S6Component } from './components/s6/s6.component';
import { S7Component } from './components/s7/s7.component';
import { GaComponent } from './components/ga/ga.component';
import { ServiciosLiveComponent } from './components/servicios-live/servicios-live.component';
import { SanSonToquesComponent } from './sanson-toques.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { GalleryModule } from '@ngx-gallery/core';
import { NuestrosArtistasComponent } from './components/nuestros-artistas/nuestros-artistas.component';


@NgModule({
  declarations: [
    HeaderComponent,
    SanSonToquesComponent,
    HomeComponent,
    S1Component,
    S2Component,
    S5Component,
    S4Component,
    S6Component,
    S7Component,
    GaComponent,
    ServiciosLiveComponent,
    NuestrosArtistasComponent],
  imports: [
    SansonToquesRoutingModule,
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    GalleryModule,
    CommonModule,
    SansonToquesRoutingModule,
    

  ]
})
export class SansonToquesModule { }
