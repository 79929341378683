import { Component, OnInit } from '@angular/core';
import { GalleryItem, ImageItem } from '@ngx-gallery/core';

@Component({
  selector: 'app-ga',
  templateUrl: './ga.component.html',
  styleUrls: ['./ga.component.scss'],
})
export class GaComponent implements OnInit {
  images: GalleryItem[];

  constructor() {}

  ngOnInit(): void {
    this.images = [
      new ImageItem({
        thumb: 'assets/images/galeria3/small/9.jpeg',
        src: 'assets/images/galeria3/9.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/images/galeria3/small/8.jpg',
        src: 'assets/images/galeria3/8.jpg',
      }),
      new ImageItem({
        thumb: 'assets/images/galeria3/small/10.jpeg',
        src: 'assets/images/galeria3/10.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/images/galeria3/small/11.jpeg',
        src: 'assets/images/galeria3/11.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/images/galeria3/small/12.jpeg',
        src: 'assets/images/galeria3/12.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/images/galeria3/small/13.jpeg',
        src: 'assets/images/galeria3/13.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/images/galeria3/small/1.jpg',
        src: 'assets/images/galeria3/1.jpg',
      }),
      new ImageItem({
        thumb: 'assets/images/galeria3/small/2.jpg',
        src: 'assets/images/galeria3/2.jpg',
      }),
      new ImageItem({
        thumb: 'assets/images/galeria3/small/3.jpg',
        src: 'assets/images/galeria3/3.jpg',
      }),
      new ImageItem({
        thumb: 'assets/images/galeria3/small/4.jpg',
        src: 'assets/images/galeria3/4.jpg',
      }),
      new ImageItem({
        thumb: 'assets/images/galeria3/small/5.jpg',
        src: 'assets/images/galeria3/5.jpg',
      }),
      new ImageItem({
        thumb: 'assets/images/galeria3/small/6.jpg',
        src: 'assets/images/galeria3/6.jpg',
      }),
      new ImageItem({
        thumb: 'assets/images/galeria3/small/7.jpg',
        src: 'assets/images/galeria3/7.jpg',
      }),
    ];
  }
}
