<div class="con5">
  <div class="container">
    <h3 class="card-title">SERVICIOS</h3>
    <p class="card-text ">Los integrantes de Grupo Sanson Cubano les acompañarán brindándoles servicios de excelente
      calidad en los momentos más importantes.
    </p>
    <!-- <p>
      Entre sus principales servicios se encuentran:
    </p> -->
    <div class="row justify-content-md-center">
      <div class="col-sm-12 col-md-3 align-self-center">
        <div class="card mb-3 border-0">
          <div class="card-body sec-info">
            <img src="../../assets/flat/wedding-couple.svg">
            <h5 class="card-info">Música para ceremonia</h5>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-3 align-self-center">
        <div class="card mb-3 border-0">
          <div class="card-body sec-info">
            <img src="../../assets/flat/cocktail.svg">
            <h5 class="card-info">Música para cóctel</h5>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-3 align-self-center">
        <div class="card mb-3 border-0">
          <div class="card-body sec-info">
            <img src="../../assets/flat/dance.svg">
            <h5 class="card-info">Música para bailar</h5>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-3 align-self-center">
        <div class="card mb-3 border-0">
          <div class="card-body sec-info">
            <img class="img-big" src="../../assets/flat/team.svg">
            <h5 class="card-info ">Música para eventos empresariales</h5>
          </div>
        </div>
      </div>
      <!-- <div class="soundcloud ">
        <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/66503379&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=true"></iframe>
      </div> -->
    </div>

    <p class="card-text">Contamos con paquetes para boda que incluyen:</p>
    <ul>
      <li>Ceremonia</li>
      <li>Música</li>
      <li>Amenización</li>
      <li>Recepción</li>
      <li>Luces</li>
      <li>DJ</li>
    </ul>
  </div>
</div>
