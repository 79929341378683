<div class="container containerdiv">
  <h1 class="text-center pb-3">¿Quienes somos?</h1>

  <div class="row">
    <div class="col-md ">
      <p class="text-center ">
        Somos <b>Sanson</b>, nos dedicamos a la producción de eventos culturales
        y conmemorativos de fechas especiales de manera presencial y
        transmisiones en vivo en las diferentes redes sociales para el público
        del centro comercial, incluimos montaje de escenario, sonido amplio,
        portafolio de artistas de todos los géneros y equipos profesionales de audio y video. Estamos respaldados por <b>10 años</b>
        de experiencia profesional.
      </p>
    </div>
   
  </div>
  <div>
    <div class="col-md ">
      <iframe class="text-center" width="100%" height="400" src="https://www.youtube.com/embed/5E9F9XM8KNw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>
</div>
