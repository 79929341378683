import { Component, OnInit } from '@angular/core';
import { NgscrollService } from 'src/app/sanson-toques/services/ngscroll.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(public ngScrollService: NgscrollService) { }

  ngOnInit(): void {
  }

  public goToABout(){
    this.ngScrollService.goTo('about');
  }

  public goToServices(){
    this.ngScrollService.goTo('services');
  }

  public goToHome() {
    this.ngScrollService.goTo('header');
  }

  
  public goToGallery() {
    this.ngScrollService.goTo('gallery');
  }

  public goToContact() {
    this.ngScrollService.goTo('contactus');
  }
}
