<div class="con6">
    <div class="container">
      <div class="title-icon">
        Siguenos en
      </div>
      <div class="banner-div">
        <div class="row justify-content-md-center cont-size ">
          <div class="col-12 align-self-center">
            <a href="https://www.instagram.com/sansoneventos">
              <img class="img-ico" src="assets/icons/insta.svg" alt="">
            </a>
            <a href="https://www.youtube.com/channel/UCf9rE1nDRirO32J8n0YzROA">
              <img class="img-ico" src="assets/icons/youtube.svg" alt="">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>