
<div class="con2">
    <div class="container containerdiv">
      <h1 class=" text-center pb-4">¿Que ofrecemos?</h1>
  
      <div class="row justify-content-md-center">
        <div class="row py-2" style="width: 100%">
          <div class="col-sm-12 col-md-6 align-self-center">
            <div class="hidden-text">
              <div class="card mb-3 border-0">
                <div class="card-body text-wrap">
                  <h5 class="card-title">Variedad de música</h5>
                  <p class="text-justify">
                    Contamos un gran repertorio de música para ofrecer la mejor
                    ambientación musical en centros comerciales.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 align-self-center">
            <div class="hidden-text">
              <div class="card mb-3 border-0" style="padding-left: 28px">
                <div class="col my-auto text-center">
                  <img
                    style="transform: rotate(20deg)"
                    class="pb-4 img-container"
                    src="assets/icons/maracas.png"
                    width="80"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="row py-2" style="width: 100%">
          <div class="col-sm-12 col-md-6 order-sm-1 order-md-2 align-self-center">
            <div class="card mb-3 border-0">
              <div class="card-body text-wrap">
                <h5 class="card-title">Los mejores músicos</h5>
                <p class="text-justify">
                    Ponemos a su servicio los mejores músicos de Bogotá formato solista, duo, trío, cuarteto y orquesta en diferentes géneros musicales.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 order-sm-2 order-md-1 align-self-center">
            <div class="hidden-text">
              <div class="card mb-3 border-0" style="padding-left: 28px">
                <div class="col my-auto text-center">
                  <img
                    style="transform: rotate(-35deg)"
                    class="pb-4 img-container"
                    src="assets/icons/saxofon.png"
                    width="80"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="row py-2" style="width: 100%">
          <div class="col-sm-12 col-md-6 align-self-center">
            <div class="hidden-text">
              <div class="card mb-3 border-0">
                <div class="card-body text-wrap">
                  <h5 class="card-title">Conducción de espacios</h5>
                  <p class="text-justify">
                    Tenemos Jueves de cultura, fechas especiales, sorteos y
                    ambientación de eventos para los centros comerciales.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 align-self-center">
            <div class="hidden-text">
              <div class="card mb-3 border-0" style="padding-left: 28px">
                <div class="col my-auto text-center">
                  <img
                    class="pb-4 img-container"
                    src="assets/icons/excelencia.png"
                    width="80"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="row py-2" style="width: 100%">
          <div class="col-sm-12 col-md-6 order-sm-1 order-md-2 align-self-center">
            <div class="card mb-3 border-0">
              <div class="card-body text-wrap">
                <h5 class="card-title">Transmisión de eventos en vivo</h5>
                <p class="text-justify">
                  Transmitimos sus eventos via streaming con la mejor tecnología y
                  calidad profesional de audio y video.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 order-sm-2 order-md-1 align-self-center">
            <div class="hidden-text">
              <div class="card mb-3 border-0" style="padding-left: 28px">
                <div class="col my-auto text-center">
                  <img
                    class="pb-4 img-container"
                    src="assets/icons/reproductor-de-video.png"
                    width="80"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  
  <!--
<div class="container my-4">
    <h1 class="text-center py-2 mont-font" >Servicios</h1>

  <div class="row pt-4">
    <div class="col-lg mx-3 text-center">
      <img class="pb-4" src="assets/icons/maracas.png" width="80" />
      <div class="row">
        <h3 class="my-auto mx-auto">Variedad de música</h3>
        <p class="pt-3 fm">
          Contamos un gran repertorio de música para ofrecer la mejor
          ambientación musical en comerciales.
        </p>
      </div>
    </div>
    <div class="col-lg mx-3 text-center">
      <img class="pb-4" src="assets/icons/saxofon.png" width="80" />
      <div class="row">
        <h3 class="my-auto mx-auto">Los mejores músicos</h3>
        <p class="pt-3 fm">
          Ponemos a su servicio los mejores músicos de Bogotá en formato
          solista, duo, trío, cuarteto y orquesta en diferentes géneros
          musicales.
        </p>
      </div>
    </div>
    <div class="col-lg mx-3 text-center">
      <img class="pb-4" src="assets/icons/excelencia.png" width="80" />
      <div class="row">
        <h3 class="my-auto mx-auto">Conducción de espacios</h3>
        <p class="pt-3 fm">
          Tenemos Jueves de cultura, fechas especiales, sorteos y ambientación
          de eventos para los centros comerciales.
        </p>
      </div>
    </div>
    <div class="col-lg mx-3 text-center">
      <img
        class="pb-4"
        src="assets/icons/reproductor-de-video.png"
        width="80"
      />
      <div class="row">
        <h3 class="my-auto mx-auto">Transmisión de eventos</h3>
        <p class="pt-3 fm">
          Transmitimos sus eventos via streaming con la mejor tecnología y
          calidad profesional de audio y video.
        </p>
      </div>
    </div>
  </div>
</div>


<div class="container mx-auto containerdiv">
  <h1 class="card-title text-center pb-5">¿Que ofrecemos?</h1>

  <div class="container">
    <div class="row row-cols-sm-2 ">

      <div class="col ">
        <h3 class="my-auto mx-auto">Variedad de música</h3>
        <p class="pt-3 fm text-justify  ">
          Contamos un gran repertorio de música para ofrecer la mejor
          ambientación musical en comerciales.
        </p>
      </div>
      <div class="col my-auto text-center">
        <img class="pb-4 img-container" src="assets/icons/maracas.png" width="80" />
      </div>

      <div class="col my-auto text-center">
        <img class="pb-4 img-container " src="assets/icons/saxofon.png" width="80" />
      </div>
      <div class="col ">
        <h3 class="my-auto mx-auto">Los mejores músicos</h3>
        <p class="pt-3 fm text-justify">
          Tenemos Jueves de cultura, fechas especiales, sorteos y ambientación
          de eventos para los centros comerciales.
        </p>
      </div>

      <div class="col ">
        <h3 class="my-auto mx-auto">Conducción de espacios</h3>
        <p class="pt-3 fm text-justify">
          Contamos un gran repertorio de música para ofrecer la mejor
          ambientación musical en comerciales.
        </p>
      </div>
      <div class="col my-auto text-center">
        <img class="pb-4 img-container" src="assets/icons/excelencia.png" width="80" />
      </div>

      <div class="col my-auto text-center">
        <img class="pb-4 img-container " src="assets/icons/reproductor-de-video.png" width="80" />
      </div>
      <div class="col ">
        <h3 class="my-auto mx-auto">Tranmisión de eventos</h3>
        <p class="pt-3 fm text-justify">
          Transmitimos sus eventos via streaming con la mejor tecnología y
          calidad profesional de audio y video.
        </p>
      </div>

    </div>
  </div>
</div>
-->
