<div class="container">
    <div class="row justify-content-md-center">
      <div class="row" style="width: 100%;">
        <div class="col-sm-12 col-md-6 align-self-center">
            <div class="hidden-text">
              <div class="card mb-3 border-0" style="padding-left: 28px;">
                <div>
                  <iframe src="https://www.instagram.com/sansoneventos/embed" width="400" height="420" 
                  frameborder="0" scrolling="no" allowtransparency="true"></iframe>
                </div>
              </div>
            </div>
          </div>
        <div class="col-sm-12 col-md-6 align-self-center">
          <div class="hidden-text">
            <div class="card mb-3 border-0">
              <div class="card-body text-wrap">
                <h5 class="card-title">Nuestros artistas</h5>
                <p class="card-text">
                    Conoce nuestro catalogo de artistas y nuestra variedad de generos musicales:
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>