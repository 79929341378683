import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { CentrosComercialesModule } from './centros-comerciales/centros-comerciales.module';
import { SansonToquesModule } from './sanson-toques/sanson-toques.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    CentrosComercialesModule,
    SansonToquesModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
