import { Component, OnInit } from '@angular/core';
import { NgscrollService } from '../../services/ngscroll.service';

@Component({
  selector: 'app-servicios-live',
  templateUrl: './servicios-live.component.html',
  styleUrls: ['./servicios-live.component.scss'],
})
export class ServiciosLiveComponent implements OnInit {
  constructor(public ngScrollService: NgscrollService) {}

  ngOnInit() {}

  public goToS7() {
    this.ngScrollService.goTo('s7');
  }
}
