import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './cc.component.html',
  styleUrls: ['./cc.component.scss']
})
export class CcComponent {
  title = 'centros comerciales';
 
}

