<div class="con1">
  <div class="slideg">
    <div id="info">
      <!-- <img id="logo" src="assets/img/sansonlogo.png"> -->
      <div id="pattern"></div>
      <div id="data">
        <div id="data_down">
          <p class="titleBack">SanSon Cubano</p>
          <p class="title">SanSon Cubano</p>
          <p class="sub-title">música variada profesional para sus eventos</p>
          <!-- <iframe src="assets/sound/intro.ogg" allow="autoplay" id="audio"></iframe> -->
          <audio controls autoplay>
            <!-- <audio controls autoplay> -->
            <source src="assets/sound/intro2.ogg" type="audio/ogg">
            <source src="assets/sound/intro2.mp3" type="audio/mpeg">
            Tu explorador web no soporta el elemento audio
          </audio>
        </div>
      </div>
    </div>
    <video id="videoBG" poster="assets/img/poster1.png" defaultMuted playsinline autoplay muted loop>
      <source src="assets/img/10crop.mp4" type="video/mp4">
    </video>
    <div id="shadowSmall">
      <video id="videoBGs" poster="assets/images/galeria3/11.jpeg" defaultMuted playsinline autoplay muted loop>
        <source src="assets/img/crop/stithcrop3.mp4" type="video/mp4">
      </video>
    </div>
    
  </div>
</div>