<div class="con3">
  <div class="container">
    <h3 class="card-title">GALERIA</h3>
    <p class="card-text">
      Definitivamente el Grupo Sanson Cubano les ofrecerá diversión total y
      entretenimiento completo.
    </p>
    <div class="row justify-content-md-center">
      <gallery [items]="images" [gestures]="true" [autoPlay]="true"></gallery>
    </div>
  </div>
</div>
