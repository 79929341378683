import { Component, OnInit } from '@angular/core';
import { GalleryItem, ImageItem } from '@ngx-gallery/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  images: GalleryItem[];

  constructor() { }

  ngOnInit(): void {
    this.images = [
      new ImageItem({
        thumb: 'assets/images/cc/1.jpeg',
        src:  'assets/images/cc/1.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/images/cc/2.jpeg',
        src:  'assets/images/cc/2.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/images/cc/3.jpeg',
        src:  'assets/images/cc/3.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/images/cc/4.jpeg',
        src:  'assets/images/cc/4.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/images/cc/5.jpeg',
        src:  'assets/images/cc/5.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/images/cc/6.jpeg',
        src:  'assets/images/cc/6.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/images/cc/7.jpeg',
        src:  'assets/images/cc/7.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/images/cc/8.jpeg',
        src:  'assets/images/cc/8.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/images/cc/9.png',
        src:  'assets/images/cc/9.png',
      }),
      new ImageItem({
        thumb: 'assets/images/cc/10.jpeg',
        src:  'assets/images/cc/10.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/images/cc/11.jpeg',
        src:  'assets/images/cc/11.jpeg',
      }),
      new ImageItem({
        thumb: 'assets/images/cc/12.jpeg',
        src:  'assets/images/cc/12.jpeg',
      }),

    ];
  }

}
