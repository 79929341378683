<nav class="navbar fixed-top  navbar-expand-lg navbar-dark bg-black">
  <a class="navbar-brand" href="#">
    <img src="assets/img/sansonlogo.png" height="60" class="d-inline-block logo" alt="" />
  </a>


  <p class="title-logo">SanSon</p>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse nav-move" id="navbarSupportedContent">
    <ul class="navbar-nav mx-auto">
      <li class="nav-item ">
        <a
          class="nav-link"
          href="#"
          (click)="goToHome()"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          >INICIO <span class="sr-only">(current)</span></a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="#"
          (click)="goToABout()"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          >ACERCA DE</a
        >
      </li>
     <li class="nav-item">
        <a
          class="nav-link"
          href="#"
          (click)="goToServices()"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          >SERVICIOS</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="#"
          (click)="goToGallery()"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          >GALERIA</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          href="#"
          (click)="goToContact()"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          >CONTÁCTENOS</a
        >
      </li>
    </ul>
  </div>
</nav>