<div class="con6">
  <div class="container">
    <div class="title-icon">
      Siguenos en
    </div>
    <div class="banner-div">
      <div class="row justify-content-md-center cont-size ">
        <div class="col-12 align-self-center">
          <a href="https://www.facebook.com/sansoncubano.musicacubana">
            <img class="img-ico" src="assets/icons/facebook.svg" alt="">
          </a>
          <a href="https://www.instagram.com/sansoncubano/">
            <img class="img-ico" src="assets/icons/insta.svg" alt="">
          </a>
          <a href="http://www.youtube.com/c/sansoncubano">
            <img class="img-ico" src="assets/icons/youtube.svg" alt="">
          </a>
          <a href="https://twitter.com/sansoncubano">
            <img class="img-ico" src="assets/icons/twitter.svg" alt="">
          </a>
        </div>
      </div>
    </div>
  </div>
</div>