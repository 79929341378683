import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-s7',
  templateUrl: './s7.component.html',
  styleUrls: ['./s7.component.scss'],
})
export class S7Component implements OnInit {
  @Input()
  mostrarMensaje: boolean = true;

  constructor() {}

  ngOnInit() {}

  openWhastApp() {
    window.location.href = 'https://api.whatsapp.com/send?phone=573103171380';
  }

  openMailTo() {
    window.location.href = 'mailto:gruposansoncubano@gmail.com';
  }

  callNow() {
    window.location.href = 'tel:3103171380';
  }
}
