import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CcComponent } from './cc.component';
import { CcRoutingModule } from './cc-routing.module';
import { SwiperModule } from 'swiper/angular';
import { NavbarComponent } from './Shared/navbar/navbar.component';
import { HeaderComponent } from './components/header/header.component';
import { AboutComponent } from './components/about/about.component';
import { FooterComponent } from './Shared/footer/footer.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { GalleryModule } from '@ngx-gallery/core';
import { GalleryComponent } from './components/gallery/gallery.component';
import { RepertoryComponent } from './components/repertory/repertory.component';
import { ServicesComponent } from './components/services/services.component';



@NgModule({
  declarations: [
    CcComponent,
    NavbarComponent,
    HeaderComponent,
    AboutComponent,
    FooterComponent,
    ContactusComponent,
    GalleryComponent,
    RepertoryComponent,
    ServicesComponent
  ],
  imports: [
    CommonModule,
    CcRoutingModule,
    SwiperModule,
    GalleryModule
  ]
})
export class CentrosComercialesModule { }
