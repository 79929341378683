<div class="con7">
  <div class="subcontainer">
    <div class="container">
      <h2 class="titleBack">SanSon Cubano</h2>
      <h3 class="title">Reserva ya !</h3>
      <table>
        <tr (click)="callNow()">
          <td>
            <img src="assets/icons/call.svg" />
          </td>
          <td class="sub_line">310 317 13 80</td>
        </tr>
        <tr (click)="openWhastApp()">
          <td>
            <img src="assets/icons/whatsapp.svg" />
          </td>
          <td class="sub_line">310 317 13 80</td>
        </tr>
        <tr (click)="openMailTo()">
          <td>
            <img src="assets/icons/gmail.png" />
          </td>
          <td class="sub_line">gruposansoncubano@gmail.com</td>
        </tr>
      </table>
      <p *ngIf="mostrarMensaje" class="card-text">
        *Trabajamos por horas, el valor de la hora puede variar según el sitio,
        el número de músicos y cantidad de sonido.
      </p>
    </div>
  </div>
</div>
