import { ViewChild, ElementRef, Inject, Injectable } from '@angular/core';
import { PageScrollService, PageScrollInstance, PageScrollOptions } from 'ngx-page-scroll-core';


import { DOCUMENT } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class NgscrollService {

  @ViewChild('container', null)
  private container: ElementRef;

  constructor(private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any) {
  }

  public goTo(place): void {
    const pageScrollOptions: PageScrollOptions = { document: this.document, scrollTarget: '#' + place, scrollOffset: 90 , duration:700 };
    const pageScrollInstance: PageScrollInstance = new PageScrollInstance(pageScrollOptions);
    //const pageScrollInstance: PageScrollInstance = PageScrollInstance.simpleInstance(this.document, '#' + place);
    this.pageScrollService.start(pageScrollInstance);
  }
}
