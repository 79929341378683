import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-s6',
  templateUrl: './s6.component.html',
  styleUrls: ['./s6.component.scss']
})
export class S6Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
