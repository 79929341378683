<div class="con2">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="row" style="width: 100%;">
        <div class="col-sm-12 col-md-6 align-self-center">
          <div class="hidden-text">
            <div class="card mb-3 border-0">
              <div class="card-body text-wrap">
                <h5 class="card-title">Serenata cubana </h5>
                <p class="card-text">
                  Los mejores y más conocidos temas de la música cubana.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 align-self-center">
          <div class="hidden-text">
            <div class="card mb-3 border-0" style="padding-left: 28px;">
              <div class="videoWrapper">
                <iframe allowFullScreen="allowFullScreen"
                  src="https://www.youtube.com/embed/Lins6L0WLDE?ecver=1&amp;iv_load_policy=1&amp;showinfo=0&amp;yt:stretch=16:9&amp;autohide=1&amp;color=red&amp;width=560&amp;width=560"
                  width="560" height="315" allowtransparency="true" frameborder="0">
                </iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" style="width: 100%;">
        <div class="col-sm-12 col-md-6 order-sm-1 order-md-2 align-self-center">
          <div class="card mb-3 border-0">
            <div class="card-body text-wrap">
              <h5 class="card-title">Boleros</h5>
              <p class="card-text">
                ¿Cansado de lo mismo? Somos la mejor opción por nuestra calidad musical y nuestro variado repertorio, contamos con sonido propio incluido en el precio por hora.
                El Son Cubano, Salsa, música tropical, boleros la mejor opción para acompañar tus reuniones y eventos</p>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 order-sm-2 order-md-1 align-self-center">
          <div class="card mb-3 border-0" style="padding-left: 28px;">
            <div class="videoWrapper">
              <iframe allowFullScreen="allowFullScreen"
                src="https://www.youtube.com/embed/85GN96BuA7M?si=mguKljcd0uhZujuP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                width="560" height="315" allowtransparency="true" frameborder="0">
              </iframe>
            </div>
          </div>
        </div>
      </div>

      <div class="row" style="width: 100%;">
        <div class="col-sm-12 col-md-6 align-self-center">
          <div class="card mb-3 border-0">
            <div class="card-body text-wrap">
              <h5 class="card-title">Música tropical</h5>
              <p class="card-text">
                Interpretamos lo mejor de la música  tropical Colombiana, Pacho Galán, Carlos Vives, Lucho Bermúdez, entre otros.
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 align-self-center">
          <div class="card mb-3 border-0" style="padding-left: 28px;">
            <div class="videoWrapper">
              <iframe allowFullScreen="allowFullScreen"
                src="https://www.youtube.com/embed/dO9DnFl0mRs?si=AjhtUnk4A7gLX0yv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                width="560" height="315" allowtransparency="true" frameborder="0">
              </iframe>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="card mb-3"> -->

      <!--  -->
      <!-- <div class="card-body">
          <h5 class="card-title">BIENVENIDOS</h5>
          <p class="card-text ">
            Diez años de experiencia profesional, somos una agrupación con manejo de diversos generos musicales para
            eventos y conformado por músicos profesionales con experiencia y renombre.
          </p>
          <p class="card-text"><small class="text-muted">Experiencia y calidez humana</small></p>
        </div>
        <img class="card-img-top" src="assets/img/san2.jpeg" alt="Card image cap">
      </div> -->
    </div>

    <!-- <div class="row justify-content-md-center">
      <div id="accordion" style="width: 100%;">
        <div class="card">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                aria-controls="collapseOne">
                Misión
              </button>
            </h5>
          </div>
          <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
            <div class="card-body">
              <p class="card-text card-text-c">Prestar servicios especializados de salud, acompañamiento y estadía de
                calidad para la población adulto mayor que brinden seguridad y estabilidad a sus familiares y al
                paciente . Promoviendo el cuidado físico, emocional, mental y espiritual de nuestros residentes,
                mejorando así su calidad de vida.</p>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingTwo">
            <h5 class="mb-0">
              <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo"
                aria-expanded="false" aria-controls="collapseTwo">
                Visión
              </button>
            </h5>
          </div>
          <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
            <div class="card-body">
              <p class="card-text card-text-c">Para el año 2023 ser una institución líder, reconocida por su excelente
                servicio y calidad humana, con reconocimientos sociales por nuestro aporte a la mejora de la calidad de
                vida del adulto mayor, expandiéndose en otros sectores de Bogotá y a nivel nacional.</p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</div>