<app-header></app-header>
<app-s1 id="s1"></app-s1>
<app-servicios-live id="s5"></app-servicios-live>
<app-nuestros-artistas id="nuestros-artistas"></app-nuestros-artistas>
<app-s2 id="s2"></app-s2>
<!-- <app-s5 id="s5"></app-s5> -->
<app-s4 id="s4"></app-s4>
<app-ga id="s3"></app-ga>
<app-s7 id="s7"></app-s7>
<app-s6 id="s6"></app-s6>
