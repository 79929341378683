import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nuestros-artistas',
  templateUrl: './nuestros-artistas.component.html',
  styleUrls: ['./nuestros-artistas.component.scss']
})
export class NuestrosArtistasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
