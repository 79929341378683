<div class="con4">
  <div class="container">
    <h3 class="card-title">REPERTORIO</h3>
    <p class="card-text ">El Grupo Sanson Cubano acordara previamente con los contratantes para seleccionar de manera
      general lo que han pensado para el día del evento y complacer sus gustos. Entre los géneros con lo que pueden
      animarse encontrarán:
    </p>

    <div>
      <ul>
        <li>Música cubana</li>
        <li>Colombiana</li>
        <li>Balada Pop</li>
        <li>Boleros</li>
        <li>Latín de jazz</li>
        <li>Música Bailable formato orquesta</li>
      </ul>
    </div>
    <div class="videoWrapper">
      <iframe src="https://open.spotify.com/embed/playlist/7ojvk4FP8fSl841YoEBpPH?si=TcJ1HDALT9-W0qDljiPbDQ" width="300"
        height="350" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
    </div>

    <!-- <h3 class="card-title">INSTRUMENTOS</h3>
    <p class="card-text ">El Grupo Sanson Cubano acordara previamente con los contratantes para seleccionar de manera
      general lo que hemos pensado para el día del evento y complacer sus gustos. Entre los géneros con lo que pueden
      animarse encontrarán:
    </p> -->

  </div>
</div>
